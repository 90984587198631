.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: url(../../static/icons/cancel.svg) 50%/2em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: 2;
}

.delete-plan {
    padding: 15px;
    color: #000;
    background: url(../../static/icons/cancel2.svg) 50%/2em auto no-repeat;
    border: 0;
    opacity: 2;
    position: absolute;
    right: -15px;
    top: -15px;
}
.form-section{
    position: relative;
}
.planning-form{
    /* border-color: 1px solid #003f54 ; */
    height: 100px;
}
.image-icon{
    width: 30px;
}