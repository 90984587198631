/* common css starts here */
.basic-colour {
    color: #61bfb5 !important;
}
.title-color, .title-color:hover{
    color: #003f54;
}
.button-style{
    color: #fff;
    font-weight: 600;
    background-color: #003f54;
    border: #003f54;
}
.lang a:hover {
    color: #61bfb5 !important;
}
.filter-title {
    font-weight: 550 !important;
    font-size: 14px !important;
}
.filter-scroll div div div{
    font-size: 14px;
}

/* .filter-scroll div div{
    padding: 0px !important;
} */
.css-1s2u09g-control{
    background-color: #E8F8F3 !important;
    border-color: #61bfb5 !important;
}
.css-g1d714-ValueContainer, .css-1d8n9bt{
    overflow-y: auto !important;
    width: fit-content;
    /* max-height: 38px !important; */
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;
}
/* .filter-height div div{
    overflow-y: auto !important;
    max-height: 38px !important;
    -ms-overflow-style: none;  /* IE and Edge */
    /* scrollbar-width: none;
} */ */
.css-g1d714-ValueContainer::-webkit-scrollbar {
    display: none;
}
.nav-tabs .nav-link.active{
    color: #fff;
    font-weight: 600;
    background-color: #003f54;
    border-color: #dee2e6 #dee2e6 transparent;
}
.nav-tabs .nav-link{
    color: #fff;
    font-weight: 500;
    background-color: #61bfb5;
    border-color: #dee2e6 #dee2e6 transparent;
}
.nav-link {
    display: block;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem !important;
}
.nav-tabs {
    border-bottom: none;
}
.month-tab{
    /* min-height: 300px; */
    /* border: 0.5px solid;
    border-top: none;
    border-color: rgb(30, 29, 29);
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; */
}
.css-1rhbuit-multiValue{
    background-color: #61bfb5 !important;
}
.table thead th {
    vertical-align: bottom;
    /* border-bottom: 2px solid black !important; */
}
.table-bordered td, .table-bordered th {
    border: 1px solid black;
}
/* table td {
    border-top: 0px !important;
} */
/* tr{
    border-bottom: 1px solid #dee2e6;
} */
/* .hCpHso {
    background-color: #E8F8F3 !important;
    position: absolute;
    width: 100%;
    top: 90%;
    overflow-y: auto !important;
    max-height: 200% !important;
    -ms-overflow-style: none;  /* IE and Edge */
    /* scrollbar-width: none;
}
.hCpHso>ul>li:hover , .hCpHso>ul>li.selected{
    background-color: #61bfb5 !important;
} */ */
.table-bordered td{
    padding: 0px 10px;
    text-align: center;
    vertical-align: middle;
}
.fDbOPw > input {
    text-align: center;
    padding: 0 !important;
}
.timings{
    width: 10%;
}
.day-header{
    width: 10% !important;
}
.delete-icon{
    width: 32px;
    height: 32px;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #003f54;
    background-color: #003f54;
}
.custom-control-input{
    z-index: 999 !important;
    width: 2rem;
}
.custom-control{
    z-index: 0 !important;
}
.available{
    background-color: green;
}
.not-available{
    background-color: red ;
}
.timepicker {
    width: 98% !important;
    position: absolute;
    z-index: 999;
    top: 285px;
    border: 0.25px solid #61bfb5;
    background-color: #61bfb5 !important;
}
.datepicker {
    background-color: #E8F8F3 !important;
}
.form-fields{
    background-color: #E8F8F3 !important;
    border-color: #61bfb5 !important;
}
.date-size{
    font-size: large;
}
.header-box-size {
    min-width: 11% !important;
    max-width: 11% !important;
    white-space: nowrap !important;
    /* border-right: 1px solid black !important; */
}
/* .table-bordered thead td, .table-bordered thead th {
    border-bottom-width: none;
} */
.border {
    border: 1px solid #efede2;
}
.planning-timeline div div div div{
    overflow:hidden !important;
}
.planning-timeline div div div div svg g text{
    display: none !important;
}

table thead th{
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
}
.main-width{
    width: 100% !important;
}
.employee-width{
    width: 10% !important;
    white-space: nowrap;
}
.date-width{
    width: 11% !important;
}
.total-width{
    width: 8% !important;
    white-space: nowrap;
}
.delete-width{
    width: 5% !important;
}
.text-vertical-center{
    vertical-align: middle !important;
}
.workstation-border{
    border-bottom: 1px solid black !important;
}
@media (max-width: 1200px){
    .modal-dialog {
        max-width: 1140px;
    }
}

.filter-scroll div div::-webkit-scrollbar {
    width: 5px;
}
.filter-scroll-emp div div::-webkit-scrollbar {
    width: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.add-icon {
    width: 20px;
}
.all-total-width{
    width: 13%;
}
.wrapper div ul{
    max-height: 10vh;
    overflow: auto;
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.image2-icon {
    width: 25px;
    margin: 15% 0px;
}
.delete-popup {
    filter: blur(2px);
}
.auto-search {
    width: 100%;
    height: 100%;
}
.filter-scroll div:nth-child(2) {
    overflow: auto;
}
.filter-scroll-emp div:nth-child(2) {
    overflow: auto;
}

.filter-scroll-emp div:nth-child(4) {
    /* overflow: auto; */
    width: auto !important;
}
.single{
    padding: 3.56rem 0.5rem !important;
}
p, td, th, h1, h2, h3, h4, h5, h6, a, div, small{
    cursor: default;
}
.pointer{
    cursor: pointer !important;
}
.non-pointer {
    cursor: default !important ;
}
tbody {
    display: block;
    /* height: 45vh; */
    /* overflow: auto; */
    /* -ms-overflow-style: none !important;  IE and Edge */
    /* scrollbar-width: none !important; */
}
thead, tbody tr {
    display: table;
    width: 100%;
}
.workstation-width{
    width: 10%;
}
.day-overview{
    /* max-height: 36vh !important; */
}
.customscroll {
    position: relative;
    overflow-y: auto;
    max-height: 40vh;
    top: 0;
    right: 0;
}
.customscroll::-webkit-scrollbar {
    width: 5px;
}
.customscroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}
.customscroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
.customscroll::-webkit-scrollbar-thumb:hover {
    background: red;
    padding-top: 20px;
}
/* .pad-right{
    padding-right: 5px !important;
} */
table{
    border: 1px solid black;
}
thead {
    border-bottom: 1px solid black;
}
.emp-name{
    border-right: 1px solid black !important;
}
.left-nav button{
    min-width: 35px;
    background: none;
    margin: 0;
    border: 0;
    outline: none;
}
.left-nav {
    margin-bottom: 2px !important;
    position: absolute;
    /* top: -55px; */
    /* display: flex; */
    /* height: 44px; */
}
.box {
    float: left;
    height: 15px;
    width: 15px;
    /* border: 1px solid black; */
    clear: both;
}
.planitem-icons{
    width: 13px;
    height: 12px;
}
.planitem-icons-2{
    width: 12px;
    height: 12px;
}
.filter-dropdown{
    width: 32.33%;
    padding-right: 2%;
}
.head-title{
    padding-left: 32%;
    padding-right: 40%;
}
.back-icon {
    width: 20px;
}
.font-navigate {
    font-size: 22px;
}
.workstation-width p, .week-header th {
    font-size: 14px;
}
.plan-item-font{
    font-size: 10px ;
}
@media screen and (min-width: 1590px) {
    .plan-item-font{
        font-size: 15px;
    }
    .shift-dropdown div {
        min-height: 16px !important;
        font-size: 15px !important;
    }
    .edit-icon{
        width: 22px !important;
        height: 26px !important;
    }
}
.title-position {
    position: unset;
}
.disabled-field, .css-1insrsq-control {
    border: none !important;
    /* opacity: 0.75 !important; */
    background-color: #E8F8F3 !important;
    color: hsl(0, 0%, 60%);
}
.disabled-icon {
    opacity: 0.25;
    cursor: default !important;
}



/* CSS for time picker */
.time-picker{
    position: absolute;
    bottom: 70%;
    background-color: white;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    border-bottom-right-radius: 3px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    border-bottom-left-radius: 3px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    border-top-right-radius: 3px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    border-top-left-radius: 3px;
}
.ui-timepicker-table {
    display: inline-table;
    width: 0;
}
.ui-widget {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 1em;
}
.ui-timepicker-table td {
    padding: 0.1em;
    width: 2.2em;
}
.ui-timepicker-hours, .ui-timepicker-minutes {
    padding: 0.2em;
}
.ui-timepicker-table .ui-timepicker-title {
    line-height: 1.8em;
    text-align: center;
}
.ui-widget-header {
    border: 1px solid #61bfb5;
    background: #61bfb5;
    color: black;
    font-weight: bold;
}
.ui-helper-clearfix {
    min-height: 0;
}
.ui-timepicker-table table {
    margin: 0.15em 0 0 0;
    border-collapse: collapse;
}
.ui-timepicker-table th.periods {
    padding: 0.1em;
    width: 2.2em;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
    border: 1px solid #61bfb5;
    background: #61bfb5;
    font-weight: normal;
    color: #fff;
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: 1px solid #61bfb5;
    background: #E8F8F3;
    font-weight: normal;
    color: #454545;
}
.ui-widget-content {
    border: 1px solid #ddd !important;
    /* background: #E8F8F3; */
    font-weight: normal;
    color: #454545;
}
.ui-timepicker{
    border: none;
}
.ui-timepicker-table td a {
    display: block;
    padding: 0.2em 0.3em 0.2em 0.5em;
    width: 2.2em;
    cursor: pointer;
    text-align: right;
    text-decoration: none;
}


/* New date picker css */
.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: #E8F8F3;
  color: #003f54 !important;
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
  border-top: 1px solid bisque;
}

.rmdp-container .custom-calendar.ep-arrow[direction="top"] {
  border-bottom: 1px solid bisque;
}
.rmdp-week-day{
    color: #003f54 !important;
}
.rmdp-day, .rmdp-arrow, .rmdp-header-values{
    color: #003f54 !important;
}
.rmdp-arrow{
    border-color: #003f54 !important;
}
.rmdp-input {
    border: 1px solid #61bfb5 !important;
    background-color: #E8F8F3 !important;
    border-radius: 5px;
    height: 38px !important;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
}
.rmdp-container{
    width: 95%;
}
.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #61bfb5 !important;
    box-shadow: 0 0 3px #61bfb5;
    color: #fff;
}
.rmdp-day.rmdp-today span {
    background-color: unset !important;
    color: #003f54 !important;
}

/* //newwww */

.form-check-input:checked {
    background-color: #61bfb5 !important;
    border-color: #61bfb5 !important;
}
.form-check-input {
    border-color: #61bfb5 !important;
}
.top-mar{
    margin-top: 16% !important;
}

.shift-dropdown div {
    min-height: 16px !important;
    font-size: 9px;
}
.edit-icon{
    width: 15px;
    height: 15px;
}
.edit-leave-icon {
    width: 22px;
    height: 22px;
}
.leave-sun-icon {
    width: 24px;
    height: 24px;
}
.leave-cancel-icon {
    width: 18px;
    height: 18px;
}
#leave-background {
    background-color: #ff00006b;
}
