body{
  background: url(./static/icons/updated_INDII_Background2.png) no-repeat center center fixed;
  background-size: cover;
}
#loading-icon {
  position: absolute;
  float: left;
  top:100%;
  left:0;
  right: 0;
  text-align: center;
  margin: auto;
}

