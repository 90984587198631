.navbar .region.region-navigation {
    width: 97%;
    float: left;
    position: relative;
}
@media (min-width: 768px){
    .navbar-header {
        float: left;
    }
    .navbar-collapse {
        width: auto;
        border-top: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .navbar-right {
        float: right!important;
        margin-right: 0px;
    }
    .navbar-nav>li {
        float: left;
    }
}
.navbar-header {
    float: left;
    width: 19% !important;
    margin-top: 0.5rem !important;
}

.pull-left {
    float: left!important;
}
.navbar-btn {
    margin-top: 8px;
    margin-bottom: 8px;
}
a {
    /* color: #337ab7; */
    color: #2c2d2f;
    text-decoration: none;
}
a {
    background-color: transparent;
}
.logo img {
    width: auto;
    height: 40px;
}
img {
    vertical-align: middle;
}
.navbar .region.region-navigation>section#block-homeicon-2,  .navbar .region.region-navigation>section#menu-icon{
    margin-top: 0.5rem;
    z-index: 2;
}
section#block-homeicon-2, section#menu-icon {
    float: right;
}
section#block-homeicon-2 div#default-home-icon a {
    background: url(./icons/home_icon_1.png) no-repeat;
    width: 50px;
    height: 34px;
    float: left;
    margin-top: 9px;
    background-size: 83%;
}
section#menu-icon div#default-menu-icon {
    background: url(./icons/hamburger-menu.svg) no-repeat;
    width: 50px;
    height: 34px;
    float: left;
    margin-top: 9px;
    background-size: 83%;
}
section#block-loggedinusername {
    float: right;
    margin-right: 2%;
    margin-top: 0.75rem;
    font-size: 20px;
    font-weight: bold;
}
#loggedin-username {
    font-size: 18px;
    /* font-family: "poppins"; */
    font-weight: 500 !important;
    /* color: #fff; */
    color: #181717;
}
section#block-languageswitcher {
    float: right;
    margin-right: 2%;
    margin-top: 1rem;
    font-size: 16px;
}
.links li {
    display: inline-block;
    margin-left: 9px;
    border-right: 1px solid;
    padding-right: 9px;
}
.language-link.is-active {
    font-weight: bold;
    text-decoration: underline;
}
.links li:last-child {
    border-right: 0px;
}
.menu-maindiv {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 0;
}
.navbar-toggle {
    position: relative;
    float: right;
    padding: 10px;
    margin-top: 8px;
    margin-right: 0px;
    background-color: transparent;
    background-image: none;
    border: 0px solid transparent;
    border-radius: 4px;
}
.navbar-default .navbar-toggle {
    border-color: #ddd;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.navbar-default .navbar-toggle .icon-bar {
    /* background-color: #ffe100; */
    background-color: #61bfb5;
}
.navbar-toggle .icon-bar {
    display: block;
    width: 32px;
    height: 3px;
    border-radius: 1px;
}
.navbar-default .navbar-toggle {
    border-color: #ddd;
}
.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
    box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%);
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #e7e7e7;
}
.collapse {
    display: none;
}
.navbar-collapse {
    width: auto;
    float: right;
    clear: both;
    margin-top: -10px;
}
.navbar-collapse ul {
    width: 100%;
    background: #fff;
    margin: -1px auto;
    text-align: center;
    border-radius: 2px;
    float: left;
}
nav#block-wagewatcher-account-menu .navbar-nav>li {
    float: left;
    width: 100%;
}
.navbar-right li a{
    font-family: Ubuntu;
    font-weight: bold;
    font-size: 12px;
    color: #5a5a5f;
    text-decoration: none;
}
nav#block-wagewatcher-account-menu {
    position: absolute;
    top: 48px;
    right: 31px;
    z-index: 9999;
}

.navbar-collapse ul li {
    clear: both;
}
.nav>li {
    position: relative;
    display: block;
}
.navbar-default, nav.navbar-nav>li>a {
    color: #777;
}
.navbar-right{
    width: 100%;
}
.navbar-right li a {
    font-family: Ubuntu;
    font-weight: bold;
    font-size: 12px;
    color: #5a5a5f;
    text-decoration: none;
    padding: 10px 20px;
    border-bottom: 1px solid #D3D4D4;
    width: 100%;
    float: left;
}
section#block-dashboardicon div#default-dashboard-icon a {
    background: url(./icons/dashboard_icon_1.png) no-repeat;
    width: 38px;
    /* height: 77px; */
    height: 60px;
    float: left;
    /* margin-top: 9px; */
    margin-top: 10px;
    background-size: 83%;
}
.navbar .region.region-navigation>section#block-dashboardicon, .navbar .region.region-navigation>section#block-partnericonblock, .navbar .region.region-navigation>section#block-homeicon-2, .navbar .region.region-navigation>section#menu-icon {
    margin-top: 0.5rem;
    z-index: 2;
}
section#block-dashboardicon {
    float: right;
}
nav#block-wagewatcher-account-menu:before {
    border-top: 1px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #fff;
    float: right;
    margin-right: 10px;
    content: '';
}







