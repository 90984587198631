.react-calendar{
    width: 100% !important;
    /* background-color: ; */
}
.react-calendar__month-view__weekdays__weekday{
    background-color: #61bfb5;
    color: #fff;
    border: 0.5px solid #E8F8F3;
}
.react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none !important;
    cursor: pointer !important;
}
.react-calendar__month-view__weekNumbers div{
    border: 1px solid #fff;
    background-color: #003f54;
    color: #fff;
}
.react-calendar__month-view__days button{
    border: 1px solid #efede2;
}
.react-calendar__tile--active{
    background-color: #61bfb5;
    color: black;
}
.react-calendar__tile:enabled:hover, .react-calendar__tile--active:enabled:focus, .react-calendar__navigation__arrow:hover, .react-calendar__navigation__label:hover{
    background-color: #61bfb5 !important;
}
.react-calendar__tile--now{
    background-color: #c6f6e7;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    border: 1px solid #fff;
    background-color: #003f54;
    color: #fff;
}
.react-calendar__month-view__days__day--weekend{
    color: black;
}
.react-calendar__navigation{
    margin-bottom: 2px !important;
    position: absolute;
    top: -50px;
    left: -4x;
}
.react-calendar__month-view__days__day--neighboringMonth p{
    display: none;
}
.react-calendar__month-view__days__day--neighboringMonth div{
    display: none;
}
.react-calendar__month-view__days__day--neighboringMonth{
    color: #757575 !important;
}
.react-calendar__tile, .react-calendar__month-view__days__day{
    position: relative;
}
.plan-icon{
    position: absolute;
    top: 4px;
    /* bottom: 0; */
    right: 6px;
    color: #169c02;
}

.react-calendar__tile{
    padding: 25px 6.6667px;
}
.react-calendar__navigation__label, .react-calendar__navigation__prev-button, .react-calendar__navigation__next-button, .navigate{
    font-size: 22px;
}
.react-calendar__navigation__label__labelText {
    text-transform: capitalize;
}
.react-calendar__navigation__label{
    pointer-events: none;
}